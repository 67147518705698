import { KeyboardEvent, useEffect, useRef } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { useFocusTrap, useNoBodyScroll } from 'utils/dom';
import Icon from 'components/display/Icon';

import Portal from './Portal';

import styles from './VideoModal.module.scss';

type VideoModalProps = {
  onClose: () => void;
  isVisible: boolean;
  title: string;
  video: string;
};

const VideoModal = (props: VideoModalProps) => {
  const { title, video, isVisible, onClose } = props;

  useNoBodyScroll(isVisible);
  const ref = useRef<HTMLDivElement>(null);
  const focus = useFocusTrap(ref);

  useEffect(() => {
    // Focus the modal once open to enable keyboard closing via escape button
    if (isVisible) {
      focus.trap();
    } else {
      focus.drop();
    }
  }, [isVisible, focus]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const handleClick = () => {
    onClose();
  };

  return (
    <Portal>
      <div className={`${styles.container} ${isVisible ? styles.visible : styles.hidden}`}>
        <div className={styles.background} onClick={handleClick} role='presentation' />
        <div
          ref={ref}
          role='button'
          className={styles.modal}
          onKeyDown={handleKeyPress}
          tabIndex={0}
        >
          <button className={styles.close} type='button' onClick={onClose}>
            Close <Icon className={styles.icon} icon={faTimes} />
          </button>
          <div className={styles.content}>
            <iframe
              className={styles.video}
              title={title}
              // Prevent video from playing when closed
              src={isVisible ? video : ''}
              frameBorder='0'
            />
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default VideoModal;
