import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'components/display/Icon';
import routes from 'constants/routes';

import CtaBanner from './CtaBanner';

const SignupCtaBanner = () => (
  <CtaBanner
    title='Ready to get started?'
    description="Sign up (it's free) in under 60 seconds, or schedule a live demo to see how it works."
    renderActions={() => (
      <>
        <CtaBanner.Button href={routes.external.schedule} variant='secondary'>
          <Icon icon={faCalendarCheck} /> Schedule a Demo
        </CtaBanner.Button>
        <CtaBanner.Button hasArrow={true} href={routes.external.signUp}>
          Get Started
        </CtaBanner.Button>
      </>
    )}
  />
);

export default SignupCtaBanner;
