import type { ReactNode } from 'react';

import { Theme } from 'constants/page';

import Button, { ButtonProps } from './Button';

import styles from './CtaBanner.module.scss';

const CtaBannerButton = ({ className, ...rest }: ButtonProps) => (
  <Button className={`${styles.button} ${className || ''}`} {...rest} />
);

type CtaBannerProps = {
  title: string;
  description: string;
  renderActions: () => ReactNode;
  className?: string;
  theme?: Theme;
};

const CtaBanner = ({
  title,
  description,
  renderActions,
  className,
  theme = 'light',
}: CtaBannerProps) => {
  return (
    <div
      className={`${styles.container} ${theme === 'dark' ? styles.dark : ''} ${className || ''}`}
    >
      <div className={styles.content}>
        <h2 className={styles.heading}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.actions}>{renderActions()}</div>
    </div>
  );
};

export default Object.assign(CtaBanner, { Button: CtaBannerButton });
