import styles from './Divider.module.scss';

const shapes = {
  triangle: {
    regular: 'M1200 0L0 0 598.97 114.72 1200 0z',
    invert: 'M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z',
  },
  triangleAsymetrical: {
    regular: 'M1200 0L0 0 892.25 114.72 1200 0z',
    invert: 'M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z',
  },
};

type DividerProps = {
  shape: keyof typeof shapes;
  color?: string;
  flip?: boolean;
  invert?: boolean;
  position?: 'top' | 'bottom';
  height: number;
  className?: string;
};

const Divider = ({
  shape = 'triangleAsymetrical',
  color = 'currentColor',
  flip = false,
  invert = false,
  position = 'top',
  height,
  className = '',
}: DividerProps) => {
  const shapePaths = shapes[shape];
  const path = invert ? shapePaths.invert : shapePaths.regular;

  // Rotate only if we're inverting or position is bottom, but not both!
  // (basically XOR, but typescript doesn't want bitwise operations)
  const rotate = [invert, position === 'bottom'].filter((criteria) => !!criteria).length === 1;

  return (
    <div
      className={`${styles.divider} ${styles[position]} ${rotate ? styles.rotate : ''} ${
        flip ? styles.flip : ''
      } ${className}`}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1200 120'
        preserveAspectRatio='none'
        fill={color}
        height={height}
      >
        <path d={path} />
      </svg>
    </div>
  );
};

export default Divider;
