// TODO - figure out below types
export enum ClinicType {
  Type1 = 'Clinic Type 1',
  Type2 = 'Clinic Type 2',
  Type3 = 'Clinic Type 3',
}

export type Testimonial = {
  id: number;
  type: ClinicType;
  review: string;
  doctor: string;
  city: string;
  state: string;
  clinic: string;
  image: string | null;
};

export const TESTIMONIAL_DATA: Testimonial[] = [
  {
    id: 2,
    type: ClinicType.Type1,
    review:
      "It took me less than 5 minutes to join, and the whole process was much easier than I thought. Through Vetcove, I was introduced to different suppliers that I had not heard of before, and make no mistake, the introduction and joining was well worth it. The first order that I have placed saved me about $50 compared to my regular suppliers' prices! Vetcove is a valuable service that is totally free, and I recommend Vetcove to all the veterinary clinics.",
    doctor: 'Albert Guirguis , DVM BVSc',
    city: 'Tampa',
    state: 'FL',
    clinic: 'Racetrack Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5d26929f23034710949158d9f5d0ed3f_2.png',
  },
  {
    id: 91,
    type: ClinicType.Type1,
    review: 'Vetcove has saved me a lot of time in comparison shopping across vendors. Its great!',
    doctor: 'Sarah',
    city: 'New Richmond',
    state: 'OH',
    clinic: 'Lifetime Pet Center of New Richmond',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/6fd411316e8d4261aa8dfd119c91e47b.jpg',
  },
  {
    id: 1,
    type: ClinicType.Type1,
    review:
      'I LOVE Vet Cove! It has made the headache of comparing prices so much easier. I also love how you can add the products to your cart on Vet Cove and it automatically redirects you to the distributors website to place your order when you are ready. Very awesome website that is actually helpful and saves you time!',
    doctor: 'Alyssa Richards, RVT',
    city: 'Columbia City',
    state: 'IN',
    clinic: 'Conley & Koontz Equine Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/170624fdd65f4a5f92c17cfb98f82a4c_1.png',
  },
  {
    id: 5,
    type: ClinicType.Type1,
    review:
      "VetCove is very user friendly. The price comparison is very helpful and we really like how it sends you to the distributors' website, into your 'cart' to complete your order.  Nice to see when products are on backorder and if someone else may still have some in stock.  It has made online ordering even easier.",
    doctor: 'Ann Pawlak',
    city: 'The Plains',
    state: 'VA',
    clinic: 'The Piedmont Equine Practice',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/90ed0a9ec58b4f61a6f87ffa0b359190_5.png',
  },
  {
    id: 92,
    type: ClinicType.Type1,
    review:
      "I didn't come from the vet field and Vetcove, their staff, and website has helped greatly in learning about products and pricing structure. Thank you! This has definitely helped me in learning about alternative items from other suppliers. It's a great tool and I'm looking forward to using it in the future.",
    doctor: 'Wendy Odgen, Inventory Supervisor',
    city: 'Las Vegas',
    state: 'NV',
    clinic: 'Las Vegas Veterinary Specialty Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/10766730382344c7bae136ff4c67e0ec.jpg',
  },
  {
    id: 6,
    type: ClinicType.Type1,
    review:
      'We have completed four weeks of orders with Vetcove.  It is easy to use.  It is resulting in surprising savings! Companies that were not major suppliers of mine are getting more business.  Companies I thought were good on price are turning out not to have the best prices as often I thought they would.  Very, very helpful!',
    doctor: 'Peter F. Veling, DVM',
    city: 'St. Augustine',
    state: 'FL',
    clinic: 'Palencia Pet Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b7154dde5ae543aab52cc4ccbf18fbe0_6.png',
  },
  {
    id: 94,
    type: ClinicType.Type1,
    review:
      "LOVE IT! It's easy, we save money, it's quick, it's personal (the owners of Vetcove are on any problem ASAP!)",
    doctor: 'Kris Tajchman',
    city: 'Topeka',
    state: 'KS',
    clinic: 'West Ridge Animal Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/7b2c11cc463a4942b05645875a378bc4.jpg',
  },
  {
    id: 8,
    type: ClinicType.Type1,
    review:
      "Vetcove completely changed the way I order supplies. Suppliers are constantly changing prices, so I was forced to compare prices on 5 or 6 sites every time I ordered. Now when a price changes, boom - I can check if its cheaper somewhere else. When an item goes on backorder, boom - I can see who else has the item. It's great to have one place for everything, and it's showing by the time I've been saving.",
    doctor: 'Kim Califano',
    city: 'Colts Neck',
    state: 'NJ',
    clinic: 'Colts Neck Equine',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/6af65b0589614556a131e901e14cbd90_8.png',
  },
  {
    id: 144,
    type: ClinicType.Type1,
    review:
      'Vetcove saves us time and money. I can find the best price on our products without searching through numerous websites.',
    doctor: 'Shannon',
    city: 'Pittsburgh',
    state: 'PA',
    clinic: 'Pittsburgh Veterinary Dermatology',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/c4817e3cce2441c1a9b91c82102d7378.jpg',
  },
  {
    id: 166,
    type: ClinicType.Type1,
    review:
      'Vetcove has been really helpful for us both in technical support and inventory control! Keep up the good work :)',
    doctor: 'Jennifer Compton',
    city: 'Westmoreland',
    state: 'NY',
    clinic: 'Central New York Veterinary Medical Services',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5134b34748f44f4a85784c2996bd8c79.jpg',
  },
  {
    id: 93,
    type: ClinicType.Type1,
    review: 'Vetcove is amazing, reduced my time spent placing my orders by 75%.',
    doctor: 'Ann Jayo, LVT',
    city: 'Elko',
    state: 'NV',
    clinic: 'Elko Veterinary',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/bae0bbea67ca492b9241d08d13aa460b.jpg',
  },
  {
    id: 46,
    type: ClinicType.Type1,
    review:
      'In the supplies/products that I have ordered so far, I am amazed at the pricing difference between the 4 companies that we order from. The site is so easy to use and it is a definite time saver, along with a cost saver. I will sure tell others of this!',
    doctor: 'Jill Drinkwater, Office Manager',
    city: 'Lancaster',
    state: 'WI',
    clinic: 'Lancaster Vet Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e0915e29e38248cfa68253ef8981ec07.png',
  },
  {
    id: 123,
    type: ClinicType.Type1,
    review:
      'Vetcove has drastically decreased the amount of time it takes me to order supplies. It is so easy! I would recommend it to anyone! I love using Vetcove and it is my first thing I go to when ordering! Thank you for making such a great site!',
    doctor: 'Stephanie La Tour',
    city: 'Mahomet',
    state: 'IL',
    clinic: 'Mahomet Animal  Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/11a5e7e75db146e2ba4b4e16dc9cdc30.jpg',
  },
  {
    id: 11,
    type: ClinicType.Type1,
    review: 'I love the time I save not having to toggle back and forth between the vet suppliers!',
    doctor: 'Renee D., CVT',
    city: 'Wapole',
    state: 'MA',
    clinic: 'Vet Neurology and Pain Management Center of NE',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/f038818b53fd4b449f93136c3d7f95b3_11.png',
  },
  {
    id: 12,
    type: ClinicType.Type1,
    review:
      'I have been using Vetcove for the past 2 weeks and feel it helps a great deal with time, organization and pricing. I would recommend Vetcove to any clinic looking to get the best deals on medications. Very quick and easy to operate. 5 star!',
    doctor: 'Ryan Hirt',
    city: 'Atlanta',
    state: 'GA',
    clinic: 'Paces Ferry Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/85daee7637944e20826e77eae611d18a_12.png',
  },
  {
    id: 13,
    type: ClinicType.Type1,
    review:
      'Vetcove is so awesome. It has cut my ordering times down by at least half. I love that they bring everyone of my distributors up with price comparisons. I no longer have to have 4 tabs open while looking up who has what and what the prices are.',
    doctor: 'Mandy Jamerson',
    city: 'Gaithersburg',
    state: 'MD',
    clinic: 'Lake Forest Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/707fb91fc05d430faedd82f1688b4565_13.png',
  },
  {
    id: 17,
    type: ClinicType.Type1,
    review:
      'I absolutely love Vetcove here at Countrycare. The use of this website has actually changed my primary distributor as I can now see they are a more cost effective option for this clinic. I hope that Vetcove plans on sticking around for a while, because my entire protocol has changed here as an inventory manager because of it. Thank you!',
    doctor: 'Kat Robertson, CVT',
    city: 'Green Bay',
    state: 'WI',
    clinic: 'Countrycare Animal Complex',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/2782beed1dfb441da8fb7065eb454d1e_17.png',
  },
  {
    id: 14,
    type: ClinicType.Type1,
    review:
      'I am very impressed with Vetcove. It has really revolutionized the way I look up products for our practice thus saving us money!',
    doctor: 'Katlyn G.',
    city: 'Mondovi',
    state: 'WI',
    clinic: 'Animal Wellness Center of Buffalo Valley',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b65a4aa59eef4762a3f12f34cfbc257f_14.png',
  },
  {
    id: 16,
    type: ClinicType.Type1,
    review:
      'Vetcove has been a great help for me. As a solo practitioner of a start-up vet hospital, I was overwhelmed by many tasks including ordering. With Vetcove, Not only it helped save my time, but also help my budgeting. Thank you for all you do!',
    doctor: 'Rieko Tran, DVM',
    city: 'Triangle',
    state: 'VA',
    clinic: 'Pearl Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/93e00ad20e344e7ca765eb6c909f2b53_16.png',
  },
  {
    id: 15,
    type: ClinicType.Type1,
    review:
      'It has made things very easy to be able to see all our vendors prices at one time. I like that the carts are saved and can be updated. The checkout process is easy, it sends us straight to the vendors site and cart is uploaded automatically!',
    doctor: 'Theresa R.',
    city: 'Naples',
    state: 'FL',
    clinic: 'Angel Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5d27a363697c4f6ba411c07254133c9b_15.png',
  },
  {
    id: 145,
    type: ClinicType.Type1,
    review:
      'Thank you Vetcove for taking the time to compare my distributors so I can focus on other tasks.',
    doctor: 'Kelly',
    city: 'Woodbridge',
    state: 'VA',
    clinic: 'Davis Ford Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/f16047da59d74ae3a5d8d00609578fde.jpg',
  },
  {
    id: 18,
    type: ClinicType.Type1,
    review:
      "We love Vetcove! It links easily to the distributors we use, and even shows us PSI pricing. Any time we need a new product, we look at Vetcove first to determine where to order and where we can get the best price. It has saved me hours of time, and it's one of the few sites that I visit every day. I can't imagine my practice without it!",
    doctor: 'Sarah S.',
    city: 'Birmingham',
    state: 'AL',
    clinic: 'Inverness Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/9f5281b75f904c04a1acc84ac92ccb98_18.png',
  },
  {
    id: 95,
    type: ClinicType.Type1,
    review:
      'One of the biggest frustrations of ordering is that no one supplier will ever be a on stop shop due to supply or widely differing pricing. Vetcove makes it easy to not only find what I need, but also see who has the best deal. I used to keep an excel sheet comparing all vendors which required updating 2-3 times a year. Vetcove has saved me hours of time!',
    doctor: 'Rachel Wachter, RVT',
    city: 'Frederick',
    state: 'MD',
    clinic: 'Skylos Sports Medicine - Frederick',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/3a186ae98e19409faa4e783819042b54.jpg',
  },
  {
    id: 124,
    type: ClinicType.Type1,
    review: 'Vetcove is like the Amazon of veterinary medicine! It makes bargain hunting a breeze!',
    doctor: 'Becky N., LVT',
    city: 'Presque Island',
    state: 'ME',
    clinic: 'Presque Isle Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/df56164d1b864946b7671cb235d74135.jpg',
  },
  {
    id: 131,
    type: ClinicType.Type1,
    review:
      'I used to go to all the vendor sites and compare prices, now Vetcove does this for me. Vetcove should develop the same program for Amazon, Target, and Walmart!',
    doctor: 'Yoon Chay',
    city: 'Media',
    state: 'PA',
    clinic: 'Town & Country Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/0439b766e8134bb3ad3505ae59f95c30.jpg',
  },
  {
    id: 7,
    type: ClinicType.Type1,
    review:
      'As a solo practitioner of a 3 year old startup, I am always trying to keep costs down, so I was already doing a lot of comparison shopping online. With Vetcove, I now have the ability to do that while saving at least an hour a week. I have even caught a few instances where I had still not found the lowest price. It is so easy to use that I have even been able to add another distributor which has saved me a lot on generic drugs. I am so thankful that Vetcove is free for vets to use. The money I save and the extra time I have now are priceless to my young family. You guys rock!',
    doctor: 'Ericka Yeley, DVM',
    city: 'Marshall',
    state: 'IL',
    clinic: 'Farm & Family Veterinary Service',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/f10970de2eb44a948b6b4b14cb870b17_7.png',
  },
  {
    id: 19,
    type: ClinicType.Type1,
    review:
      'The amount of time I save ordering is priceless. Specific features allow; price shopping, sourcing backordered products, reviewing previous purchases and highlighting specific promotional items, all in one, easy to use site. We are more than pleased.  We are thrilled with Vetcove. God bless.',
    doctor: 'Chris Rehm, DVM',
    city: 'Loxley',
    state: 'AL',
    clinic: 'Rehm Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/768af8d0f39845a9a604843e6c064803_19.png',
  },
  {
    id: 20,
    type: ClinicType.Type1,
    review:
      'I have found that this is a very user friendly web app. Analogous in many ways to Kayak as one would search fares. I am fortunate that most of my main suppliers are represented in the distributor list. I recommend this to all my clients(consulting), who are vets just going into their own practice.',
    doctor: 'Robert Spiegel, DVM',
    city: 'Jacksonville',
    state: 'FL',
    clinic: 'Animal Clinic of Oceanway',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ffbb0dcffb4f4f52ab406b76a266ddf0_20.png',
  },
  {
    id: 139,
    type: ClinicType.Type1,
    review:
      'Since I discovered Vetcove (I love that I can see the price comparisons) we have been able to save money. I have shared Vetcove with several people at a conference who are now using it. Keep up the good work!',
    doctor: 'P. Gonzalez, Hospital Manager',
    city: 'Santa Paula',
    state: 'CA',
    clinic: 'Santa Paula Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/c846d0c5308649b3bb3cc20a9ee75a5b.jpg',
  },
  {
    id: 173,
    type: ClinicType.Type1,
    review: 'Vetcove Makes Ordering Easy And They have Amazing Deals',
    doctor: 'Justin De Sola',
    city: 'Astoria',
    state: 'NY',
    clinic: 'Astoria Animal Society',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/12110e4a851a40129a2cfee6d8317dad.jpg',
  },
  {
    id: 48,
    type: ClinicType.Type1,
    review:
      'Love it - this has to be the most useful tool so far in veterinary medicine. Vetcove has given me more control in my buying power with the relationship of my vendors. Having the access at my fingertips in real time makes me able to negotiate more with my preferred vendor versus their competition.',
    doctor: 'Dr. Ganon Harish, DVM',
    city: 'Buffalo',
    state: 'NY',
    clinic: 'Elmwood Small Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/7c2289ce0c33452bb7e2f46ee707d4cc.png',
  },
  {
    id: 96,
    type: ClinicType.Type1,
    review:
      'Vetcove is an incredibly helpful and easy to use tool for purchasing and tracking inventory! We love the ability to compare prices from all distributors in one convenient location.',
    doctor: 'April Splawn, Hospital Owner',
    city: 'Rockhill',
    state: 'SC',
    clinic: 'Ebenezer Veterinary Hospital, LLC',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/2cb21917ffcd448ea8cc8a901819bd0d.jpg',
  },
  {
    id: 22,
    type: ClinicType.Type1,
    review:
      "I am the ordering manager and I have to say I don't just like it...I absolutely LOVE it!  I use it everyday, sometimes multiple times a day.  It has made ordering, locating hard to find items, and price comparisons a snap. We are saving time and money.  Thank you so much for creating such a wonderful ordering platform! It truly is amazing and I don't think I could ever go back to ordering without it.\r\nYou and Mitch have my sincerest gratitude. THANKS!",
    doctor: 'Heather Kilheeney',
    city: 'Palos Park',
    state: 'IL',
    clinic: 'Mill Creek Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b2a6b95ee65d4d9f8cfa7bf493a6335c_22.png',
  },
  {
    id: 50,
    type: ClinicType.Type1,
    review:
      'We’re using it a lot and it’s great! It almost makes shopping “fun” for our technician in charge of ordering. Definitely saving me some money too, thanks :)',
    doctor: 'Joe Frost, DVM Owner',
    city: 'Loves Park',
    state: 'IL',
    clinic: 'Frost Family Pet Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/bbf4324fea4049af88dc13a9d6f1e21e_50.png',
  },
  {
    id: 49,
    type: ClinicType.Type1,
    review:
      'Things are going great- Love the website! Very user friendly and it really saves me a lot of time and the clinic money.',
    doctor: 'Jennifer Wagner, CVT',
    city: 'Menasha',
    state: 'WI',
    clinic: 'Appanasha Pet Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/348059271d2e4fddb7c67087bc4c30e1.png',
  },
  {
    id: 24,
    type: ClinicType.Type1,
    review:
      'What a great tool!!  It makes ordering easier to get the best prices from vendors.  How nice of you to share it for free!!  Love it!\r\nThank you.',
    doctor: 'Bonnie',
    city: 'Duluth',
    state: 'MN',
    clinic: 'North Shore Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/989de99620a340e682a4b91b30014fd3_24.png',
  },
  {
    id: 21,
    type: ClinicType.Type1,
    review:
      'I am now using Vetcove for the past couple of months and I just love it. My technician who used to place orders left last year and I decided to take on the task. I can get things ordered in a jiffy, I am just so ecstatic about this new wonderful platform I cannot express in simple terms. Thank you very much!',
    doctor: 'Dechamma Ballaranda, DVM',
    city: 'Eastpointe',
    state: 'MI',
    clinic: 'East Detroit Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ddd668e0d0ad4e4ba60f95037a000dbe_21.png',
  },
  {
    id: 97,
    type: ClinicType.Type1,
    review:
      'It make searching for products very easy and efficient. Readily available to asnwer questions, regarding products for my staff.',
    doctor: 'Logan C. Miller, CVT',
    city: 'Cottage Grove',
    state: 'WI',
    clinic: 'Deer-Grove Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ea164dfe78414647abeaacc8a7f137d6.jpg',
  },
  {
    id: 98,
    type: ClinicType.Type1,
    review:
      "It's so convenient to go to one place to shop and get the lowest prices. Vetcove is a game changer.",
    doctor: 'Jenny P.',
    city: 'Pittsburgh',
    state: 'PA',
    clinic: 'North Boros Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/25c95a87a58f42738e9336a579b64a46.jpg',
  },
  {
    id: 87,
    type: ClinicType.Type1,
    review:
      'I love VetCove! It has made my ordering much easier. It also has helped me getting my budget under control and track my order history with precision. Great site and a must tool for incorporating an inventory management program.',
    doctor: 'Ana Kiefer',
    city: 'San Francisco',
    state: 'CA',
    clinic: 'San Francisco Zoo',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/4a87d951ea534f3cb970e2b51b04b8e1.jpg',
  },
  {
    id: 23,
    type: ClinicType.Type1,
    review:
      "We are using it routinely and loving it! The search is easy, and we can compare prices instantly. I can't think of anything I would change. Thanks for all of your hard work!",
    doctor: 'Eva Beyer, VMD',
    city: 'Phoenix',
    state: 'AZ',
    clinic: 'South Mountain Vet Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/531e577c709e4dcc894b08d9b4e88c8e_23.png',
  },
  {
    id: 99,
    type: ClinicType.Type1,
    review:
      'It’s an awesome way to do everything in one spot. I love being able to compare numbers without having to jump around on different sites.',
    doctor: 'Ali, Head Technician and Inventory Manager',
    city: 'St. Louis',
    state: 'MO',
    clinic: 'St. Louis Cat Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/a698a9d9ff704fd9acfdc0c7dc72e5e1.jpg',
  },
  {
    id: 100,
    type: ClinicType.Type1,
    review:
      'Vetcove is the easiest way to be sure you are getting the best pricing. It is also the perfect place to check on promotions and rebate programs! It has saved us so much money!',
    doctor: 'Dani B.',
    city: 'Tempe',
    state: 'AZ',
    clinic: 'McClintock Animal Care Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/141bf3ae23b443a1bde3a6e895e32292.jpg',
  },
  {
    id: 167,
    type: ClinicType.Type1,
    review:
      'I think this site is great. I recommend it all the time. It has saved me so much time and money!',
    doctor: 'Alison M.',
    city: 'Sacramento',
    state: 'CA',
    clinic: 'Sacramento Zoo',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/3e65df67d82c42d7bc8417687ff79496.jpg',
  },
  {
    id: 29,
    type: ClinicType.Type1,
    review:
      "I just set it up and compared prices to invoices I have on my desk and it's spot on. Love the ease of use and the PICTURES!!! Thank you! and congratulations on making a very useful tool.",
    doctor: 'Suzanne Butcher',
    city: 'Canyon',
    state: 'TX',
    clinic: 'Timber Creek Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/41e324f18e6746ebbafdf2b2baaf733c_29.png',
  },
  {
    id: 30,
    type: ClinicType.Type1,
    review:
      "Vetcove has been *extremely* helpful for me when I'm ordering. I used to have to open multiple pages at once, line them all up, search each one individually, and THEN compare pricing. Vetcove has spared me the headache!",
    doctor: 'Hannah\tKienow',
    city: 'Raleigh',
    state: 'NC',
    clinic: 'Safe Haven for Cats',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/9a6f51da2820434abe7ee952646a542a_30.png',
  },
  {
    id: 28,
    type: ClinicType.Type1,
    review:
      'I absolutely love your site! It helps me order quicker, more efficiently. Thank you for offering such a great service!',
    doctor: 'Brad Seaman, DVM',
    city: 'Brooklyn',
    state: 'NY',
    clinic: 'Greenpoint Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/3ac0589a9c68427093ccb6412d812c77_28.png',
  },
  {
    id: 27,
    type: ClinicType.Type1,
    review:
      "This is a great product! We love it. I have shared it will all my friends and they also love it. It has saved us so much time and money!!! Thank you vetcove team for providing such a money saving product for free!\r\n\r\nWe have been using vetcove since it was launched. We are very happy with the website. We love how it has improved over the year. It has saved us so much time and money now that we don't have to compare prices. We are very thankful to your team for developing such an awesome website.",
    doctor: 'Zile Singh, DVM',
    city: 'Seattle',
    state: 'WA',
    clinic: 'Greenwood Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/c1ef48aa188243deb45ebfa1052026ec_27.png',
  },
  {
    id: 88,
    type: ClinicType.Type1,
    review:
      'I love that all of my ordering information is in one place, so that I can easily find backordered items, if they are still being warehoused, at an alternate vendor. Pricing is right at your fingertips.The forums are so helpful!',
    doctor: 'Tamara Fletcher',
    city: 'New York',
    state: 'NY',
    clinic: 'Heart of Chelsea Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/8f7e2abb30b9483696ad78b70a565dcb.jpg',
  },
  {
    id: 89,
    type: ClinicType.Type1,
    review:
      'I have recommended this site to all my past places of employment , I love this site :) I was out of the office for good month due to surgery and I showed my boss and coworkers how to use Vetcove to order while I was away and it made ordering very simple for them :)',
    doctor: 'Lisa Kowalski',
    city: 'Cleveland',
    state: 'OH',
    clinic: 'Green Road Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/97e852cc0e1d4e1b8d19be069b61825c.jpg',
  },
  {
    id: 32,
    type: ClinicType.Type1,
    review:
      'I was able to take some time Friday and place a couple of orders through VetCove. I have to say…I think that it’s amazing!! I’ve already told a couple of other inventory managers about it as well! Thanks for the HUGE shortcut for my job!',
    doctor: 'Kat Green',
    city: 'Greenville',
    state: 'SC',
    clinic: 'Upstate Veterinary Specialists',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/f1c444f6e0a0470d85975a15a2a5d0c9_32.png',
  },
  {
    id: 26,
    type: ClinicType.Type1,
    review:
      "Yes we use Vetcove in the spay/neuter clinic I work at and I love it! It's been a great time saver  for comparing products against our different distributors. I really like that I can save lists so reordering the same product is simple and fast. Thank you for this great service!",
    doctor: 'Micha Piraino, DVM',
    city: 'Cumberland',
    state: 'MD',
    clinic: 'Stepping Stone Animal Society',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/36fb466862894ccdbaf1a322c62ec0b6_26.png',
  },
  {
    id: 31,
    type: ClinicType.Type1,
    review:
      'Vetcove is awesome! I am amazed at the fact that this such an easy way to compare prices, that this is a safe and legitimate website that does not cost anything and has the best customer service of any online company I have ever dealt with. I have shared your information with a few friends in the veterinary field and plan to mention it again when I meet with a group of office managers later this month. Thank you for the excellent service.',
    doctor: 'Jerrah King',
    city: 'Pleasant Hill',
    state: 'OR',
    clinic: 'Pleasant Hill Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e7f40a366efd4e498cb2e853d0679218_31.png',
  },
  {
    id: 34,
    type: ClinicType.Type1,
    review:
      "I love it!! I absolutely love it! It saves so much time (and money!)\r\n\r\nAnd it's so easy. You can quote me on that.\r\n\r\nJulie\r\nLebanon Equine Clinic",
    doctor: 'Julie Cracolici, Inventory Manager',
    city: 'Lebanon',
    state: 'OH',
    clinic: 'Lebanon Equine Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/54200b0e74a44f9ba0971b44f74d0b03_34.png',
  },
  {
    id: 125,
    type: ClinicType.Type1,
    review:
      'We really enjoy the "one-stop shopping" experience that Vetcove provides. Since we started using Vetcove we have saved both time and money when ordering the products we need most.',
    doctor: 'Scott M. Stipe, Sr. D.V.M.',
    city: 'Fonda',
    state: 'NY',
    clinic: "Noah's Ark Animal Hospital",
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/3ccdc665e28d4f7fb3dd56e175389079.jpg',
  },
  {
    id: 10,
    type: ClinicType.Type1,
    review:
      'Vetcove is absolutely a 5 Star must-have application for any busy vet practice. This software has dramatically improved our ability to purchase at the best available price from all major distributors. It has also reduced the amount of time we spend performing purchasing tasks. It is beautifully and intuitively designed with the purchasing manager in mind, and the company is highly responsive to good user suggestions. "Vetcove" an international word that means \'the easiest way for veterinarians to get the best deal from their distributors\'.',
    doctor: 'Scott Carlin',
    city: 'Tulsa',
    state: 'OK',
    clinic: 'APC Veterinary',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/390868746eda4c698c62c16af2687347_10.png',
  },
  {
    id: 4,
    type: ClinicType.Type1,
    review:
      "Vet Cove has been a tremendous help as well as a time saver. Moving from a Vet Tech to Inventory Manager was definitely a big change for me but with Vetcove it has made the transition easier. It is so easy to search for the items needed as well as being able to price match between your vendors all on one page. It's simple, easy to use and just all around an awesome tool. I will continue to use Vetcove as I grow in my new career, and I know if I ever have an issue the Vetcove team will be there to assist. You could not ask for a better tool and better customer service. I certainly would recommend Vetcove to anyone in an inventory position, whether you've been doing it for two weeks or ten years.",
    doctor: 'Brad Reynolds',
    city: 'Columbia',
    state: 'SC',
    clinic: 'South Carolina Veterinary Specialist and Emergency Care',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b893318ea13d420bab73bdf3c478317c_4.png',
  },
  {
    id: 102,
    type: ClinicType.Type1,
    review:
      'Vetcove is very user friendly. I enjoy doing all my shopping in one place. It makes ordering very quick and easy, and I love that you can compare prices all in one spot. It has saved me lots of time and money.',
    doctor: 'Sierra Laskowski',
    city: 'Helena',
    state: 'MT',
    clinic: 'Helena Veterinary Service',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/815eda53db274e62b572e46614ed84ff.jpg',
  },
  {
    id: 101,
    type: ClinicType.Type1,
    review:
      'Prior to Vetcove, I would open multiple distributor websites and browse from one to another and back again. It took me hours to compare and select the best prices. Vetcove made it possible to view all my distributors in one location and truly compare prices. It eliminated the time I used to have to spend researching for sales and the best prices.',
    doctor: 'Kelly Spagnoli, Practice Manager',
    city: 'Orlando',
    state: 'FL',
    clinic: 'Avalon Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5c4fb3d8371e4fd19cf490168a68b586.jpg',
  },
  {
    id: 132,
    type: ClinicType.Type1,
    review:
      'Vetcove is super easy to use! We love the fact that it compares prices for us so we know we are always getting the best deal for our practice.',
    doctor: 'Michele Jourdan, AVA, CVPM',
    city: 'Wyoming',
    state: 'MI',
    clinic: 'Clyde Park Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/23f8d96b0c394d62b3989d080ceb5894_132.jpg',
  },
  {
    id: 133,
    type: ClinicType.Type1,
    review:
      "I can't imagine the past 2 years without Vetcove. It absolutely has made ordering and maintaining my inventory much easier.",
    doctor: 'Dr. Deb Robertson',
    city: 'Manhattan Beach',
    state: 'CA',
    clinic: 'South Bay Mobile Vet',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/33223fc324bb4c1aa73ba1140f008c65.jpg',
  },
  {
    id: 140,
    type: ClinicType.Type1,
    review:
      'Our practice is currently updating our inventory guidelines, tracking, and ordering procedures. Vetcove has made it so much easier. Since cost is always a concern, it is so nice that this service is offered for free. Best new technological contribution to our field in awhile!',
    doctor: 'Samantha Riddels, DVM',
    city: 'Sherman',
    state: 'TX',
    clinic: 'Lange Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/46e270e7fbbd4b3bb4fd345d822ea925.jpg',
  },
  {
    id: 178,
    type: ClinicType.Type1,
    review:
      'With Vetcove, comparing prices between distributors is a breeze.  Shop, purchase, check-out - all in one place.',
    doctor: 'Stacey McKnight, CVPM',
    city: 'Ellenwood',
    state: 'GA',
    clinic: 'Fairview Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e5eaa7547ace4a5eaadbccc374486221.jpg',
  },
  {
    id: 36,
    type: ClinicType.Type1,
    review:
      'It has definitely been very useful!! This website has helped me spend less time ordering and made in sooo much easier to cut costs by being able to see the price for the same product from all my distributors. I have found things cheaper at opposite distributors than what I was getting for from my other one!! And it streamlines my ordering!',
    doctor: 'Sara Hess, RVT',
    city: 'Asheville',
    state: 'NC',
    clinic: 'Asheville Veterinary Associates',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/72b7a9c1aa3a4207a5bbbef889e84fcc.png',
  },
  {
    id: 35,
    type: ClinicType.Type1,
    review:
      'I have used Vetcove several times and it is a great resource for us. Not only does it save me time with placing orders, but we are saving money by finding the lowest prices available. Thank you very much for making this excellent site available to us!! The website is easy to use, and is a staple at our practice.',
    doctor: 'Steve Askin, VMD',
    city: 'West Kennebunk',
    state: 'ME',
    clinic: 'Animal Welfare Society',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/800cebb8e9d441879ff6801845b1b733.png',
  },
  {
    id: 43,
    type: ClinicType.Type1,
    review:
      'Thank you for your hard work! Vetcove let me load my cart so, I only had to go to my suppliers. Still saved time! I appreciate your efforts to make my job easier.',
    doctor: 'Michelle, Inventory Manager',
    city: 'Genoa',
    state: 'OH',
    clinic: "Owen's Ark Veterinary Clinic",
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/90659e7657584995b59bced6f2112fb3.png',
  },
  {
    id: 106,
    type: ClinicType.Type1,
    review:
      'Take 5 minutes to link your accounts, save yourself hours of work in the future. It REALLY is that easy with Vetcove!',
    doctor: 'Ann Frank, RVT',
    city: 'Frederick',
    state: 'MD',
    clinic: 'Veturgency',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/adff960b0f114d6782a83d14359b720d.jpg',
  },
  {
    id: 38,
    type: ClinicType.Type1,
    review:
      "I love it and I've told everyone I know on Facebook and practice manager groups. Thanks for creating such a useful site!",
    doctor: 'Amy Short-Jones, CVT',
    city: 'Appleton',
    state: 'WI',
    clinic: 'Fox River Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/6ddcc96fdbba4ad98a33552e687bb1a5_38.png',
  },
  {
    id: 41,
    type: ClinicType.Type1,
    review:
      'I like the platform and it seem very easy to navigate. I placed my order on Monday without any issues. It’s really nice to be able to see things in one place instead of having to have multiple web browsers open to look for the best price or looking for something that is on backorder. Really glad this was developed!',
    doctor: 'Kerry Balding, Practice Manager',
    city: 'Indianapolis',
    state: 'IN',
    clinic: 'The Paw Patch Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b9147e227cb342f189c5b84a386a6a1c.png',
  },
  {
    id: 44,
    type: ClinicType.Type1,
    review:
      'We are utilizing Vetcove and feel it does save us time. We like that the promotions pop up, that we see if we have ordered from that vendor in the past. All good stuff.',
    doctor: 'Monique Pierpont, RVT',
    city: 'Jupiter',
    state: 'FL',
    clinic: 'Harmony Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/17a68a1b0fb1486994b07dcbe34bba43.png',
  },
  {
    id: 40,
    type: ClinicType.Type1,
    review:
      'Your site has become indispensable! I use it every time I need to order. Congrats for coming up with the idea!',
    doctor: 'Kenton Flaig, DVM, Owner',
    city: 'Portville',
    state: 'NY',
    clinic: 'Portville Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/dbc597f989bc4d8d89938d00c6a3b895.png',
  },
  {
    id: 39,
    type: ClinicType.Type1,
    review:
      'I’m very happy with Vetcove. I’ve used it now for a few orders and I feel it does save me time from doing the legwork in comparing vendors myself. It also gives me a better idea of the range of products available. I like it.',
    doctor: 'Johnathan Augusto, DVM',
    city: 'Milford',
    state: 'MA',
    clinic: 'Milford Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/3e32f3cd20c6420a930f1d96e1620fcf.png',
  },
  {
    id: 37,
    type: ClinicType.Type1,
    review: 'I really enjoy the ease it has made my ordering!',
    doctor: 'Melissa Unzicker',
    city: 'Platteville',
    state: 'WI',
    clinic: 'Family Pet Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/8ea3d069fccb4b248f00a30418462a8a_37.png',
  },
  {
    id: 107,
    type: ClinicType.Type1,
    review: "Convenient! It's very easy to find the products I need.",
    doctor: 'EYNC',
    city: 'Naperville',
    state: 'IL',
    clinic: 'C-Vet Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/cc7b66226b1b460581589955743540f7.jpg',
  },
  {
    id: 127,
    type: ClinicType.Type1,
    review:
      "I visit Vetcove first and I don't need to go to any other website to place my order. Good stuff!",
    doctor: 'Beth Hill',
    city: 'Great Falls',
    state: 'MT',
    clinic: 'Westside Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/a1f018cfdbf44cca9374ff4f0a775e25.jpg',
  },
  {
    id: 129,
    type: ClinicType.Type1,
    review: 'We love Vetcove. It makes ordering great and has saved us hundreds!',
    doctor: 'Kristen Vance, DVM',
    city: 'Bel Air',
    state: 'MD',
    clinic: 'Homeward Bound Mobile Vet',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/fc06e86bec3f4749b5a14fa975e0a768.jpg',
  },
  {
    id: 156,
    type: ClinicType.Type1,
    review:
      'I have grown to really love Vetcove. In the hectic rush of emergency Vetcove is a breath of fresh air when it comes to ordering.',
    doctor: 'Lara, RVT',
    city: 'Escondido',
    state: 'CA',
    clinic: 'Animal Urgent Care',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5143d516cfd8461a9eff4ac9915afacb.jpg',
  },
  {
    id: 108,
    type: ClinicType.Type1,
    review:
      "I can't say enough great things about Vetcove. The ability to compare prices instantly has saved us a LOT of money. Also, with a few key strokes, you can see if a product is on backorder, and potentially when it will be released.",
    doctor: 'Elizabeth Cox',
    city: 'Staunton',
    state: 'VA',
    clinic: 'Westwood Animal Hospital PLC',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/0f5ee1272f8d48c183b1585c2727bb91.jpg',
  },
  {
    id: 126,
    type: ClinicType.Type1,
    review:
      'I LOVE Vetcove! Prior to Vetcove, I had tabs open for each of our distributors and would log in to each one and search their sites individually for most items that I needed to order. Now Vetcove does that for me, freeing up precious time to do other things!',
    doctor: 'Mike Pope, DVM',
    city: 'Darien',
    state: 'GA',
    clinic: 'Darien Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e37b52eb679d487cbe1eccbb5aa46d31.jpg',
  },
  {
    id: 55,
    type: ClinicType.Type1,
    review:
      'I forwarded this to our office manager. She came and thanked me right away because she absolutely loves it!! She said it is going to save her so much time. Thanks for sending us this great information.',
    doctor: 'Shelly Martin, DVM',
    city: 'Tuscon',
    state: 'AZ',
    clinic: 'Adobe Veterinary Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/4259be89e5134bb587e78495ad529040.png',
  },
  {
    id: 128,
    type: ClinicType.Type1,
    review:
      'I love Vetcove to check across the board pricing and availability on new products! It is very helpful to my medical team!',
    doctor: 'Terry G., RVT',
    city: 'Akron',
    state: 'OH',
    clinic: 'Buckeye Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/490cb02728884c15b9eb18eb5dafa9ba.jpg',
  },
  {
    id: 57,
    type: ClinicType.Type1,
    review:
      'We use Vetcove and love it. It makes it so easy to find the best price and to locate some hard to find items or backordered items.',
    doctor: 'Aaron Bessmer, DVM, Owner',
    city: 'Souix City',
    state: 'IA',
    clinic: 'Elk Creek Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/7329d06c80d84a2ea520de5a7ea83716.png',
  },
  {
    id: 59,
    type: ClinicType.Type1,
    review:
      'I use Vetcove and I am very happy with it! I really like your reports, analytics. Thank you again for this great opportunity and best of luck! I always recommend you to all managers during meetings.',
    doctor: 'Kasia Guzy, Hospital Administrator',
    city: 'Pittsfield',
    state: 'MA',
    clinic: 'Berkshire Vet',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/d57856159ab0423ea2a8e717b579a89f_59.png',
  },
  {
    id: 61,
    type: ClinicType.Type1,
    review:
      'Use it all the time, love it! Simplifies ordering by a hundred fold saves me on average an hour a week or more ordering.',
    doctor: 'Lisa Immerblum, LVT',
    city: 'Goldens Bridge',
    state: 'NY',
    clinic: 'Goldens Bridge Veterinary Care Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e187b0b3eebb49c1a019b8c81bb8d84e.png',
  },
  {
    id: 53,
    type: ClinicType.Type1,
    review:
      "Vetcove fast became a practice management bible of sorts. Complete product and supply lines for multiple distributors are available at your fingertips. Purchase history and order facilitation are made much simpler. Vetcove's value is inestimable.",
    doctor: 'Michael P. Meaney',
    city: 'Stamford',
    state: 'CT',
    clinic: 'Stamford Veterinary Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/74374672c95a42f39c5bc382e1085fd2.png',
  },
  {
    id: 62,
    type: ClinicType.Type1,
    review: 'Vetcove has been amazing so far!  It has significantly cut down on ordering time.',
    doctor: 'Chanae Bowden, Clinical Manager',
    city: 'Denver',
    state: 'CO',
    clinic: 'Stapleton Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/28ec7a9c3205423fb431c23f677c9c2a.png',
  },
  {
    id: 56,
    type: ClinicType.Type1,
    review:
      'Your website has become a huge asset for our ordering department. It simplifies the process, drastically cuts down on time, and above all - saves us money. The customer service has been amazing and extremely responsive. We absolutely LOVE this website!',
    doctor: 'John Curry',
    city: 'Clearwater',
    state: 'FL',
    clinic: 'Clearwater Marine Aquarium',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/028dbb04d9ad45858c9114b04bc10447.png',
  },
  {
    id: 54,
    type: ClinicType.Type1,
    review:
      'As a nonprofit we are constantly trying to find the best deal to better serve our shelter and clients. Vetcove has helped us to make smart buys and keep our prices low for the community so that we can better serve our mission.',
    doctor: 'Amanda Rosado',
    city: 'Westchester',
    state: 'PA',
    clinic: 'Brandywine Valley SPCA',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/1c841c6e065a49878b8481a0c52a64c8_54.png',
  },
  {
    id: 58,
    type: ClinicType.Type1,
    review: 'My technician LOVES it! Thank you again for creating such a great service.',
    doctor: 'Kimberlee Buck, DVM',
    city: 'Birch Run',
    state: 'MI',
    clinic: 'Frankenmuth-Birch Run Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5246451033764739823a29bf9293ba9a.png',
  },
  {
    id: 52,
    type: ClinicType.Type1,
    review:
      'We currently use Vetcove at both clinics and love it.  I really like the updates that were recently made.  Let me know if you need anything else.',
    doctor: 'Lauren Merrell, Practice Manager',
    city: 'Mesquite',
    state: 'TX',
    clinic: 'Town East Galloway + Creek Crossing Animal Clinics',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/92ce7fa3692e4607a86d805cda51b30a.png',
  },
  {
    id: 63,
    type: ClinicType.Type1,
    review:
      "I love Vetcove!  The convenience of having everything all in one place is great, and I really appreciate not having to navigate a half-dozen different websites with a half-dozen different designs.  In Vetcove, the interface is user-friendly and completely consistent from item to item, and from supplier to supplier.  While I can easily access my suppliers' websites at any time (the links are there on every single item), I really only need to do so when it's time to check out.  Vetcove is a tremendous time-saver, and I can't imagine doing my ordering any other way.",
    doctor: 'Lynda Reid, Practice Manager',
    city: 'Levelland',
    state: 'TX',
    clinic: 'College Avenue Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/a57506b712ac4134a437bc3b89d637f9.png',
  },
  {
    id: 110,
    type: ClinicType.Type1,
    review:
      "Vetcove saves me so much time. Before Vetcove I would have to do my ordering with four or five vendor windows open to find the best price and now it's all on one screen.",
    doctor: 'Cindy, CVT',
    city: 'Sherwood',
    state: 'OR',
    clinic: 'Cat Adoption Team',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/012f0a2837194b8f9cc098e97ca97225.jpg',
  },
  {
    id: 64,
    type: ClinicType.Type1,
    review:
      'I have been using it and I love it. All of our distributors are in one place. It makes price comparisons very easy as well as showing alternate products if something is not available.',
    doctor: 'Jessica Best, CVT',
    city: 'St. Petersburg',
    state: 'FL',
    clinic: 'Partridge Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/0d333100540d40659c4c6dce18f11df8_64.png',
  },
  {
    id: 47,
    type: ClinicType.Type1,
    review: 'Thanks for creating such an amazing site! Wish I would have found it sooner.',
    doctor: 'Jamin Chenault, Practice Manager',
    city: 'Goode',
    state: 'VA',
    clinic: 'Riverside Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b2962043b6cf42ba874744c75305940a.png',
  },
  {
    id: 45,
    type: ClinicType.Type1,
    review:
      "I've been using it for months and I love it. Makes ordering so much easier since I don't have to compare prices myself. It's easy to train people to order when I'm off since they don't have to wonder which distributor they get what from. I also like the free samples - I just discovered that option this week and already got a bunch of samples.",
    doctor: 'Lindsey Moyer, CVT',
    city: 'Annville',
    state: 'PA',
    clinic: 'Annville-Cleona Veterinary Associates',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/cfd215ce91c2470da6fa676203b46f44.png',
  },
  {
    id: 90,
    type: ClinicType.Type1,
    review:
      'Vetcove is incredible! They literally save me hours by helping us streamline our inventory process. In addition to time, they also help us save money by comparing all of our major vendors in one place. We would highly recommend them.',
    doctor: 'Nate Crawford, Practice Manager',
    city: 'Thomaston',
    state: 'GA',
    clinic: "Hannah's Mill Animal Hospital",
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/bd87ff6daa394d63978101eb4906297c_90.png',
  },
  {
    id: 113,
    type: ClinicType.Type1,
    review:
      "Vetcove has been a real time saver for us. Instead of opening multiple tabs, typing (and retyping) product names, looking at the price, clicking back to see the other distributor's price, etc, we now have one window open to Vetcove. All our distributors are there, with any special pricing pre-loaded. Quick and simple to compare prices. They even break it down to per tab price! Fantastic!",
    doctor: 'Michael Goldmann, DVM',
    city: 'Nanuet',
    state: 'NY',
    clinic: 'Nanuet Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/1b4be0b55e3441cfaaae8b7953e1ff39.jpg',
  },
  {
    id: 112,
    type: ClinicType.Type1,
    review:
      'Vetcove has significantly reduced the amount of time I spend on ordering each week by allowing me to compare all my distributors in one place.',
    doctor: 'Jennifer Bricker',
    city: 'Brewer',
    state: 'ME',
    clinic: 'Eastern Maine Emergency Vet Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/4deb0a6e7b7642c08a59c5171eb3d2cd.jpg',
  },
  {
    id: 115,
    type: ClinicType.Type1,
    review:
      "I love being able to go to one site and see price differences. It's has saved me lots of money and time!",
    doctor: 'Kira MacKinnon',
    city: 'Evans',
    state: 'WA',
    clinic: 'Echo Ridge Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/8a870eebd8dc4bcabd765648063f84e2.jpg',
  },
  {
    id: 109,
    type: ClinicType.Type1,
    review:
      'I love the community feel of Vetcove and the opportunity for managers to discuss products or issues. The organization Vetcove brings to inventory and ordering, making sure I am getting a product I ordered before and providing accurate history and prices, as well as the updates on backorder or alternate stocking.. extremely helpful, and easier to read than the vendor websites.',
    doctor: 'Monica Saunders',
    city: 'Rockford',
    state: 'IL',
    clinic: 'Animal Clinic of Rockford',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ca697b5978734d55b4b6b84ef5ec88c1.jpg',
  },
  {
    id: 42,
    type: ClinicType.Type1,
    review:
      'I’ve been ordering inventory for over 17 years, every rep tells me that their pricing is great, the best, etc.  Now I no longer have to wonder.  I log into vetcove and type in what I want and KNOW which distributor gives me best price.  I chose my items for the entire day.  Leave everything in multi distributor carts and go back repeatedly adjusting and adding.  At the end of my day I hit one button and all the sites open and I complete my orders.  Quick, best pricing, everything I order, I can see where I’ve ordered it before, if it is in stock and sometimes discover things I would have never known without Vetcove. I’m saving time, money and a have unbelievably convenient way to order.  If I have a problem, they respond real time, immediately!!!! I would pay to use vetcove, but I don’t have to – IT’S FREE.  They make ME money.  Love them!!!',
    doctor: 'Katy Joyce, CVT, Hospital Manager',
    city: 'Milford',
    state: 'CT',
    clinic: 'Pond Point Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/db09b09ec5e74cd09ee6ca394b0c8c69.png',
  },
  {
    id: 51,
    type: ClinicType.Type1,
    review:
      "Where has Vetcove been all my life?!  I cannot believe how much time and money I save by being able to quickly and easily price shop all my various distributors on one user-friendly website.  The website design is beautiful, loads quickly, and is my go-to every single day for ordering.  But what sticks with me even more than the fantastic service they provide, is their outstanding and personal customer service.  A few months after signing on with Vetcove, I received a lengthy, hand-written card containing a heartfelt thank you for using their services faithfully.  What companies do that these days?!  Any time I've had any questions or problems, I've always received immediate responses from their staff solving the issue.  I cannot thank Vetcove enough for doing what they do, so I'll continue to support them in any way that I can!",
    doctor: 'Caitlin Shaw',
    city: 'Fayetteville',
    state: 'NY',
    clinic: 'Lyndon Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/43230525040c4ae9a33efb7b568d38b1.png',
  },
  {
    id: 111,
    type: ClinicType.Type1,
    review:
      "I didn't have the time to compare prices across multiple websites, and I didn't have the time to compare different products until I started using Vetcove. It has lowered my overhead costs and made ordering much easier.",
    doctor: 'Kristian Shiver, DVM',
    city: 'Covington',
    state: 'GA',
    clinic: 'Animal Medical of Covington',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5521cfc6b662454592cca26bc8599236.jpg',
  },
  {
    id: 3,
    type: ClinicType.Type1,
    review:
      "Thank you Vetcove for saving our business so much time in ordering inventory! The veterinary community has been waiting for this development! The ability to compare all of our available products and pricing in one place is indispensable. With the ever-growing list of back-ordered items in our industry, finding alternatives through other distributors is time-consuming, but with Vetcove, it's instant! I particularly like Vetcove's genuine goal of helping veterinary businesses by keeping this service FREE. I would currently recommend Vetcove for any size veterinary clinic looking to increase inventory efficiency and reduce inventory costs (i.e. all of us). I would also recommend Vetcove to suppliers and manufacturers who want their companies and products to remain competitive as this is the future of veterinary inventory management.",
    doctor: 'Jamie Branham, CVT',
    city: 'Port Washington',
    state: 'WI',
    clinic: 'The Little Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/3fd814284d644212a72d43fea249c694_3.png',
  },
  {
    id: 9,
    type: ClinicType.Type1,
    review:
      "I have been in charge of my hospital's inventory for the past 15+ years. When I first read what Vetcove is about I thought it sounded too good to be true. I am so glad I pursued it! I've only used Vetcove for a couple weeks now and WHAT A TIME SAVER! Not only can I compare pricing on any item from all my vendors at one time but when an item was backordered through my regular supplier another one had it in stock. No phone calls, no individually checking seperate websites, no wasted time. Just punch in the name of the medication and all answers are at your fingertips. If you are constantly pressed for time I would strongly recommend giving this website a shot. The setup time was minimal and oh-so-easy. It is not just a time saver but a money saver too. If you could spend 5-10 minutes getting to know a new website that will save you countless time and money in the future why wouldn't you? Simply brilliant.",
    doctor: 'Dawn Frentzel',
    city: 'Wheaton',
    state: 'IL',
    clinic: 'Animal Medical Clinic of Wheaton',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/4af44c3c920f4037a59892e52eaec4aa_9.png',
  },
  {
    id: 116,
    type: ClinicType.Type1,
    review:
      "I absolutely love Vetcove! It has helped to save so much time in my ordering process and simplified the teaching process for someone to step in and help me when I'm gone. Love it!!",
    doctor: 'Mary Goble',
    city: 'Woodland',
    state: 'WA',
    clinic: 'Woodland Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/bb9d5d1d41144d6cbdcc62d217b4d2bb.jpg',
  },
  {
    id: 136,
    type: ClinicType.Type1,
    review: 'Vetcove is a time and money saver. I love it.',
    doctor: 'Ben Hendershott, LVT',
    city: 'Texarkana',
    state: 'TX',
    clinic: 'Pleasant Grove Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/9a439afcb5b64cc09cdf244954871515.jpg',
  },
  {
    id: 25,
    type: ClinicType.Type1,
    review:
      "I use this site all the time and have been for quite some time!  We have had an account with Vetcove for over a year now. It's a fabulous site that allows you to put in all your items and find where you can get them the cheapest.  That is how I have been able to get the lowest cost for items that we are purchasing and also the site that I use monthly to keep updated and make sure we are getting our items for reasonable costs.  It's a great bargaining tool with the vendors too, because I can easily see what each vendor is charging for the same item.",
    doctor: 'Kristen Anderson',
    city: 'Bourne',
    state: 'MA',
    clinic: 'Cape Cod Veterinary Specialists',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/f47374b82bf24732bc9f9fb7727c2ba9_25.png',
  },
  {
    id: 120,
    type: ClinicType.Type1,
    review:
      'Vetcove makes ordering faster and easier. I love seeing purchase history and price comparisons. It is easy to allocate purchases between vendors if a distributor rep did something special for me.',
    doctor: 'David Hawkins, Practice Owner',
    city: 'Greshman',
    state: 'OR',
    clinic: 'Dogwood Pet Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b1f4a92a73114d7fb2b96b64a0926432.jpg',
  },
  {
    id: 119,
    type: ClinicType.Type1,
    review:
      'Using Vetcove has made my job a lot less time consuming. I love being able to go to one place to compare pricing instead of multiple websites!',
    doctor: 'Nicole Burruss, Practice Manager',
    city: 'Dahlonega',
    state: 'GA',
    clinic: 'Red Barn Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/8600b2056f744f9db52c3a4932aa97a8.jpg',
  },
  {
    id: 130,
    type: ClinicType.Type1,
    review:
      "Vetcove is a lifesaver! No more having to check 4-5 different distributor websites! It is all there right in front of you! Thank you. If you aren't using it yet, you should be :)",
    doctor: 'Fred Davis, CVT',
    city: 'Washington',
    state: 'MA',
    clinic: 'Hilltowns Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/1a7ff98053db41eeaab595d891c25168.jpg',
  },
  {
    id: 135,
    type: ClinicType.Type1,
    review:
      'Vetcove makes price comparisons easy. It finds what your looking for from each of your distributors and organizes it side by side so you can see what the best deal is, right away.',
    doctor: 'Amy Bradford, Office Manager',
    city: 'Weaverville',
    state: 'CA',
    clinic: 'Trinity Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e108a92650d34890b25d4ca80bed9168.jpg',
  },
  {
    id: 114,
    type: ClinicType.Type1,
    review:
      'I use Vetcove exclusively....seriously the best website developed. I manage a 230k inventory and I am sure it has saved us money and time! I love it!',
    doctor: 'Siobhan Purser-Blackman, Purchasing Manager',
    city: 'North Haven',
    state: 'CT',
    clinic: 'Central Hospital for Veterinary Medicine',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/228443040401405f9236c8033df8d34b.jpg',
  },
  {
    id: 121,
    type: ClinicType.Type1,
    review:
      'Thank you for your fantastic customer support! I feel like an individual, rather than "just another client". They really take the time to help find a solution and genuinely value client suggestions. This personal touch brings me back to Vetcove every time.',
    doctor: 'Jennifer Compton, LVT',
    city: 'Westmoreland',
    state: 'NY',
    clinic: 'CNY Veterinary Medical Services',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/453fd71c7bbb426e9216850e4f123907.jpg',
  },
  {
    id: 117,
    type: ClinicType.Type1,
    review:
      'We are very happy with the services you provide. It makes ordering from multiple distributors much easier.',
    doctor: 'Gina Compton, RVT',
    city: 'Canal Winchester',
    state: 'OH',
    clinic: 'Winchester Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/69987bed5f33462aacef7e5d66549c3c.jpg',
  },
  {
    id: 137,
    type: ClinicType.Type1,
    review: 'Vetcove has helped to streamline the inventory process and save me valuable time.',
    doctor: 'Danielle T., Practice Manager',
    city: 'Bristol',
    state: 'CT',
    clinic: 'Chippens Hill Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/fa53733ae58d489aa8a8265d3962f61a.jpg',
  },
  {
    id: 118,
    type: ClinicType.Type1,
    review:
      'I love being able to see my pricing on all my distributors without having to go back and forth between websites. I like that most products are broken down into a per tab/cap price. It makes price comparisons easier.',
    doctor: 'Brandy Tabor, RVT',
    city: 'Meeker',
    state: 'OK',
    clinic: 'Meeker Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/416cf54400034c6a9670ab80969c74fe.jpg',
  },
  {
    id: 122,
    type: ClinicType.Type1,
    review: 'Love Vetcove! It cuts ordering time down by half!',
    doctor: 'Michele Ball',
    city: "O'Fallon",
    state: 'MO',
    clinic: 'Back on Track Veterinary Hospital & Rehab Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/100afae9e4624ab1b63f084a5f1faf9e.jpg',
  },
  {
    id: 154,
    type: ClinicType.Type1,
    review:
      'I highly recommend Vetcove to everyone - it cuts down costs, especially if you only purchase from one place. There are some serious savings!',
    doctor: 'Keith Krempl',
    city: 'Plano',
    state: 'TX',
    clinic: 'Lancers Square Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/af65c8533f4d4acebb020f8a884a9e8d.jpg',
  },
  {
    id: 70,
    type: ClinicType.Type1,
    review:
      'Thank you very much! I really love your site. It has saved me so much time as I no longer have to check multiple vendors for prices before ordering! I was hoping someone would invent something like this! You have a great product and do a great job with thinking through all the details. Since we discovered Vetcove, our life has been so much better. We can compare prices on one web site in seconds and place our orders through every company we use in a matter of minutes. Great invention. Great company!',
    doctor: 'Shannon Miller, DVM',
    city: 'Conyers',
    state: 'GA',
    clinic: 'Miller Veterinary Services',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/aced6ca2f4394be5a0a551dc128d7c18.png',
  },
  {
    id: 68,
    type: ClinicType.Type1,
    review:
      'We are just getting into spring here, which means increasing populations, which means having to order more supplies and medications. I am so excited about VetCove!! In the past, I felt like I spent more time bouncing from webpage to webpage to try and find the best deals, and would often end up working on my orders at home so I could at least be comfortable when I was trying to price check items! Your site is a game changer!! I have already found it to be incredibly useful...not only when making large orders, but when I am searching for one item in particular. It makes it much easier and takes much less time. I am looking forward to the convenience of it this summer when there is little time to do my orders.',
    doctor: 'Liz Gelhaar',
    city: 'Racine',
    state: 'WI',
    clinic: 'Wisconsin Humane Society',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/a3f285205ee74a8ba34118a7bcc4ade5.png',
  },
  {
    id: 66,
    type: ClinicType.Type1,
    review:
      "I love using Vetcove. It helps to find a good price, and when things are on backorder somewhere I find an alternate supplier so easily. Yesterday I had to order just stainless steel feeding bowls. It was so easy to find what I needed. I like that it lets me know when I have a history of ordering the product from a supplier. It's just great.",
    doctor: 'Sarah Bloom',
    city: 'Coral Springs',
    state: 'FL',
    clinic: 'Coral Springs Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/4548c5eeca1e4d1eaf1dbda5306d0d44.png',
  },
  {
    id: 65,
    type: ClinicType.Type1,
    review:
      'As an Inventory Manager for a large 24 hour Specialty and Emergency Practice I typically place 1 large order a week and often need to purchase hard to find items. Vetcove has streamlined my ordering.  From the amount of time saved placing orders,  to the amount of money that the hospital has saved in the first month is amazing! It was very easy to set up my account and the ability to find products (and getting the best price) is an Inventory Manager’s dream!  I would recommend this site for anyone who purchases for their hospital.',
    doctor: 'Danielle Muldoon',
    city: 'New Castle',
    state: 'DE',
    clinic: 'Veterinary Specialty Center of Delaware',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/517732b422cc446e975a02250dfc58ca_65.png',
  },
  {
    id: 33,
    type: ClinicType.Type1,
    review:
      'I absolutely love VetCove. I am the practice manager of a non-profit humane society where saving every penny counts. I used to price shop every single distributor for every single item which used to take me hours. VetCove has become my holy grail. I place my orders in 20 minutes instead of two to three hours. I would be devastated if it ever went away!\r\n\r\nThanks for reaching out, and I hope the feedback is helpful.\r\n\r\nCourtney Werner, MBA\r\nVeterinary Practice Manager\r\nCaroline Earle White Veterinary Hospital\r\nAt the Women’s Humane Society',
    doctor: 'Courtney Werner, MBA',
    city: 'Bensalem',
    state: 'PA',
    clinic: "Women's Humane Society",
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e3bb7a8e3e7e45a6a7fa3c8e938fb131_33.png',
  },
  {
    id: 69,
    type: ClinicType.Type1,
    review:
      'I really love Vetcove! It has been a great help in managing inventory. It is very easy to look up information and saves me lots of time price shopping! Really happy to have it :)',
    doctor: 'Fallon Ready',
    city: 'McFarland',
    state: 'WI',
    clinic: 'Spartan Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/0fe1544340d149e2bb87aa1cb662db09.png',
  },
  {
    id: 103,
    type: ClinicType.Type1,
    review:
      'This website is such a time saver for my position as Finance and Inventory Manager! It enables me to efficiently check against 4 different distributors for pricing, availability and promotions! Thank you for this wonderful service!',
    doctor: 'Jackie Ballay, Finance and Inventory Manager',
    city: 'Erie',
    state: 'PA',
    clinic: 'Erie Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/976f059658eb41b992934f09b5d74fe0.jpg',
  },
  {
    id: 67,
    type: ClinicType.Type1,
    review:
      'I LOVE Vetcove! I used to spend so much time looking through each different distributor trying to find a medication and then trying to find the best price. This is one stop shopping and saves me so much time and there is no hassle.  I just wish that I knew about this sooner!',
    doctor: 'Dierdre Haley',
    city: 'Gilford',
    state: 'NH',
    clinic: 'MacDonald Veterinary Services',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/f01c3a17675f4c7da82b8523c2900275.png',
  },
  {
    id: 77,
    type: ClinicType.Type1,
    review:
      "Vetcove makes all my orders simple and efficient, and I know I am getting the best price. Easy to compare my different distributors for both availability, promos, and unit cost. If you aren't using them, you are paying too much for your inventory. They are also adding great new features like forums and product blogs, recall lists, and have an easy to use interface to track previous orders. This site is a blessing for any inventory manager.",
    doctor: 'Ben Shindelus, LVT',
    city: 'Sparks',
    state: 'NV',
    clinic: 'Sage Veterinary Care',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b907573426af4394a39ec182b3790f0c.png',
  },
  {
    id: 72,
    type: ClinicType.Type1,
    review:
      "Your service allows veterinary practices to not only be able compare prices from all distributors with the click of a button but to have the freedom to choose whom we give our business to based on THEIR quality of service. Vetcove encourages competition among vendors beyond just curbing unfair pricing, it actually rewards vendors that strive to offer great service to their veterinary customers even if they cannot offer the absolute lowest price.\r\n\r\nWe don't always choose the vendor with the lowest price but we DO choose among the ones with the best service for a reasonable price. Starting a new practice we would have NEVER had the time to email or call each vendor for price checks for each product we use to then decide who also offered the best service and pick a good match for us. This process would have been detrimental to our time management and financial budget. I cannot even imagine the impact that would have had on our success. \r\n\r\nSo thank you for starting Vetcove well before we started a hospital! LoL",
    doctor: 'Loretta Pesteanu',
    city: 'Brooklyn',
    state: 'NY',
    clinic: 'Loving Paws Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/323fe8cfd65e4989a40bfbf1934fb88d.png',
  },
  {
    id: 71,
    type: ClinicType.Type1,
    review:
      'Thank you for being part of Vetcove. One of my doctors learned about your services at a conference. It took us awhile to try it out, but I have to tell you that you have saved me A LOT of time on my orders. I would open all my different distributors in different tabs and toggle between them all to compare the costs. Being part of a Humane Society and a low cost clinic, I can really help keep the costs low and keep going with our fast paced environment!',
    doctor: 'Janette Fetter, CVT',
    city: 'Longmont',
    state: 'CO',
    clinic: 'Longmont Humane Society',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5cc592adebda4fc59baaec9a256d23a9.png',
  },
  {
    id: 76,
    type: ClinicType.Type1,
    review:
      'Vetcove has made ordering a much smoother and efficient process for us. Instead of comparing costs from one website to another, we can look at one site, cost compare, and order all within minutes!',
    doctor: 'Nancy Bureau, DVM',
    city: 'Niwot',
    state: 'CO',
    clinic: 'Left Hand Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/fe6f1c0321af407ba2e1189358225693_76.png',
  },
  {
    id: 75,
    type: ClinicType.Type1,
    review:
      'VetCove is the best thing to happen to veterinary medicine in the last 5 years. We LOVE it! This website has made ordering easy, fast and saves us tons of money. We could never go back to the dark ages before VetCove.',
    doctor: 'Paul Camilo',
    city: 'Weston',
    state: 'FL',
    clinic: 'Hometown Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/9329929d7b55457ba11ad34a5e48b03f.png',
  },
  {
    id: 73,
    type: ClinicType.Type1,
    review:
      "We are still relatively new to ordering with VetCove, but we don't want to remember a day with it! VetCove has made the ordering process so simple and organized from immediate price comparisons, to details on each and every product as well as order history. As a team, we have the utmost trust for VetCove and we highly recommend it, to say the least.",
    doctor: 'Demy & Maggie',
    city: 'Miami',
    state: 'FL',
    clinic: 'Eureka Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ce2a0539ecff488d999b409672f2f965_73.png',
  },
  {
    id: 78,
    type: ClinicType.Type1,
    review:
      'I have to say that I am LOVING Vetcove!!  It has made finding the best deal on any items needed, very simple.  I’m VERY happy with Vetcove and plan on continuing to use it for the future and growth of our hospital.',
    doctor: 'Deborah Fish',
    city: 'Santa Clarita',
    state: 'CA',
    clinic: 'Cinema Veterinary Centre',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/2611a3ec73e545f292a5624193c142f2.png',
  },
  {
    id: 74,
    type: ClinicType.Type1,
    review:
      'This has got to be one of the best programs I have seen in years!!!!! Very helpful and resourceful',
    doctor: 'Yvonne',
    city: 'Powder Springs',
    state: 'GA',
    clinic: 'Dallas Highway Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/346e92bff5504ae9885a22207f35a792_74.png',
  },
  {
    id: 138,
    type: ClinicType.Type1,
    review: 'Vetcove is great for rapidly allowing me to price compare and find what I need',
    doctor: 'Pilar Starman, DVM',
    city: 'Larkspur',
    state: 'CO',
    clinic: 'Larkspur Pet Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ec4677cc08ca42dc9f6a28f051f51a3a.jpg',
  },
  {
    id: 79,
    type: ClinicType.Type1,
    review:
      'Vetcove is the veterinarian’s “the miracle” program for doing it well! The ease of finding products surpasses individual distributor websites. While price shopping may be important to most, it is being able to find the less commonly stocked items. Vetcove continues to improve the order process and it is helpful when a client needs a specific product – just a click to know it is available. If there is ever a question, it is amazing how quickly Vetcove responds and solves the question. The discussions online can be helpful, especially for remote practices. I foresee Vetcove masterminding more useful additions to an awesome program and for this reason I am glad we are a part of the Vetcove family!',
    doctor: 'Delores Gockowski, DVM',
    city: 'Sturgeon Lake',
    state: 'MN',
    clinic: 'North Ridge Veterinary SVC, LLC',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/63e9433e6b854d3b96254fceff68023f.jpg',
  },
  {
    id: 80,
    type: ClinicType.Type1,
    review:
      '"VetCove.com is a game changer for the veterinary practitioner - especially for small practices. Until VetCove, we did not have the time or staff expertise to spend on shopping around for the best price, so we just picked a supplier that we thought was the least cost and used them. When we needed something that they did not have it was a lot of time and effort to search around and look for what we needed. Now, with VetCove we have all our distributors consolidated into one location to search and choose the best price and product for the need. VetCove has put purchasing power back in to the hands of the practitioner."',
    doctor: 'Ben Character, DVM',
    city: 'Eutaw',
    state: 'AL',
    clinic: 'Mobile Veterinary Service',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/7bea72ca2d274440833b79503b99da67.jpg',
  },
  {
    id: 81,
    type: ClinicType.Type1,
    review:
      'Vetcove has added time to our day!! The ease of finding the least expensive option is right at our fingertips, Vetcove helps puts us in a much better position to communicate & negotiate with our vendors.',
    doctor: 'Jay Wickham',
    city: 'Pembroke',
    state: 'MA',
    clinic: 'Pembroke Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/d4cbc5c413914e37b0cfa8af92e9e915.jpg',
  },
  {
    id: 82,
    type: ClinicType.Type1,
    review:
      'We have found Vetcove very easy to use. We use it to compare prices, of course, but also to track how often we are ordering items and how much we paid for them in the past. We can tell at a glance what is back ordered and what is available. I would recommend Vetcove to any hospital that wants to simplify ordering.',
    doctor: 'Wendy C. Brooks, DVM',
    city: 'Los Angeles',
    state: 'CA',
    clinic: 'Mar Vista Animal Medical Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/234bca293372492c9a02ca1674bc301c.jpg',
  },
  {
    id: 83,
    type: ClinicType.Type1,
    review:
      "I'm very thrilled with Vetcove! So far, it has cut down the time it takes to order our inventory, as well as keep costs in line by allowing us to see the price variance between our distributors, something we couldn't readily see before. I also enjoy the conversations between users in Discuss!",
    doctor: 'Peg Gaven',
    city: 'Bradenton',
    state: 'FL',
    clinic: 'University Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/9d6423d8ffef4dec99c4c64b71092536.jpg',
  },
  {
    id: 84,
    type: ClinicType.Type1,
    review:
      "Vetcove is a game-changer! This web site has saved me time AND money! Plus I'm able to see what's in stock and anticipate delivery delays. It's super easy to navigate and I absolutely love the community forum they've added–so helpful! I wish there were a Vetcove for every aspect of life. I don't know why you wouldn't use it!?",
    doctor: 'Meghan S. Bingham',
    city: 'Houston',
    state: 'TX',
    clinic: 'West Alabama Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e99f316ef5374b17996738a656f14935.jpg',
  },
  {
    id: 85,
    type: ClinicType.Type1,
    review:
      'VetCove has been an absolute game changer for SF SPCA since we began using it in late 2015. It was built for veterinarians (not for industry suppliers) so it allows us to do many things better and faster as well as some new things we were never able to do before!\r\n \r\nAt its core, VetCove facilitates and maximizes the SF SPCA’s prudent use of our limited resources of time and money. We are a large, multi-faceted organization and the ability to have a variety of account access levels has allowed us share an open catalog with our internal partners. Now with the power of a single smart search function, we can see not only pricing, but availability, order history, other clinic use, manufacturer info, active ingredients, and more from one screen. Moving items to the appropriate “cart” for purchase improves our ability to maximize our time and inventory dollars. The discussion section provides an opportunity for us to share and develop best practices across the industry rather than supplier-driven ‘buy-buy-buy frenzy’. Smart analytics provides a superior perspective of our purchases than what we receive through financial system reporting. The service has streamlined our sourcing process too, resulting in better, more informed, decisions on our formulary.\r\n \r\nVetCove is very intuitive, just sign up and load your accounts. No training needed to search and very little training needed to utilize other offerings. This service is so valuable to our organization that we will not add distributors/supplier without being able to sign on to our account through VetCove. The cost to Veterinarians is nothing, but the benefits are enormous. This tool has been so important to our work; I think it would significantly help any nonprofit or small vet practice just as much as it helps us, possibly even more.\r\n \r\nSusan Reeves, Director SF SPCA Supply Chain',
    doctor: 'Susan Reeves, Director SF SPCA Supply Chain',
    city: 'San Francisco',
    state: 'CA',
    clinic: 'San Francisco SPCA',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/250ccf6086b3432b870c2617d4f2c1d9_85.png',
  },
  {
    id: 86,
    type: ClinicType.Type1,
    review:
      'It has been a very positive experience and has really simplified the ordering process. Instead of pulling up multiple websites of vendors that we purchase from, I call it "one stop shopping".',
    doctor: 'Becky Vernon',
    city: 'Montclair',
    state: 'VA',
    clinic: 'Montclair Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/4df51868e58643daaba5f8c8f8c50a42.jpg',
  },
  {
    id: 143,
    type: ClinicType.Type1,
    review: 'Excellent resource for making ordering less painful!',
    doctor: 'Kevin Marecle, Owner',
    city: 'Russellville',
    state: 'AL',
    clinic: 'Russellville Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/7d874e1454a34433a437cabf83cccbbb.jpg',
  },
  {
    id: 141,
    type: ClinicType.Type1,
    review:
      'Vetcove has hands-down made inventory management easier. It is quicker to order, faster to see who has products in stock and who has them on promotion, and track who we have been buying what from. We still visit with and support our reps, but I have a much better idea about where I should get items from (and how many)!',
    doctor: 'Alex Flinkstrom, Office Manager',
    city: 'Lunenburg',
    state: 'MA',
    clinic: 'Lunenburg Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/99934da14fbb4ffa93cc8a64c4022991.jpg',
  },
  {
    id: 142,
    type: ClinicType.Type1,
    review:
      "I am so thankful that a friend told me about Vetcove. I always wondered if I was getting the best deal with each purchase but never had the time to compare prices on each distributor's website. Now I know, and it’s so easy to use!",
    doctor: 'Bonnie Abbott, DVM',
    city: 'Erie',
    state: 'CO',
    clinic: 'Vista Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/33f2fd97cd5d4fa0a85e6c9b99f4a5ba.jpg',
  },
  {
    id: 165,
    type: ClinicType.Type1,
    review:
      "Such an easy and essential time saver for our practice. I can't imagine going back to the old site by site comparison that we used to do! You guys are great, and it's been such a wonderful tool for our hospital to use. I love the forums as well!",
    doctor: 'The Bristol County Veterinary Hospital Team',
    city: 'Seekonk',
    state: 'MA',
    clinic: 'Bristol County Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/b5ec567d3935421a8007da7a914e7746_165.jpg',
  },
  {
    id: 146,
    type: ClinicType.Type1,
    review:
      'I love Vetcove. There is a lot of helpful information to complete my job thoroughly and easily!',
    doctor: 'Jessica Stumph, DVM',
    city: 'Centerville',
    state: 'OH',
    clinic: 'Dayton Care Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/31fc69bfc0d048e597e8db68baaa0402.jpg',
  },
  {
    id: 148,
    type: ClinicType.Type1,
    review:
      'Vetcove has absolutely changed the way I do ordering- in a good way! Price shopping has gone from a mundane task to an exciting experience. Sticking to a budget has never been easier and I have never been more efficient.',
    doctor: 'Sue Hutton, Hospital Administrator and Veterinary Consultant',
    city: 'Franklin',
    state: 'TN',
    clinic: 'Animalia Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/891a4f12d1f34bc9a73608bedaffbf0b.jpg',
  },
  {
    id: 149,
    type: ClinicType.Type1,
    review:
      "Vetcove is the best! It has literally saved hours of my week by streamlining all my vendors into one, easy to use site. The staff at Vetcove is wonderful too. Overall it's an A+ site! It's the only way I shop anymore.",
    doctor: 'Luke, Practice Manager',
    city: 'New York',
    state: 'NY',
    clinic: 'Lenox Hill Veterinarians',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/a096683ed4e74e639b54b17962a55a63.jpg',
  },
  {
    id: 150,
    type: ClinicType.Type1,
    review:
      "Vetcove saves me so much time! I'm able to compare our vendors so I know I'm getting the best price! I use only Vetcove to order our supplies – it's easy to use and it's free! I love it!",
    doctor: 'Lory',
    city: 'Inverness',
    state: 'FL',
    clinic: 'TLC Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5150e4b5db4542e7907ef1d4bbe53687.jpg',
  },
  {
    id: 151,
    type: ClinicType.Type1,
    review:
      'We absolutely love using Vetcove! It has made our jobs so much easier having all our vendors on one screen when searching items. Would highly recommend Vetcove to anyone who is looking for a more efficient way of stocking their practice.',
    doctor: 'Rachel Brown, CVT',
    city: 'Haslet',
    state: 'TX',
    clinic: 'Haslet Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/d9573de48a804e1cbc2255803ae0749a.jpg',
  },
  {
    id: 152,
    type: ClinicType.Type1,
    review:
      'Vetcove has been a lifesaver with ordering inventory. I used to have to price shop between 3 different distributor sites which took way too much of my time. This saves me so much time AND keeps my budget to a minimum!',
    doctor: 'Kirsten Friedrichs, Practice Manager',
    city: 'Darien',
    state: 'IL',
    clinic: 'Finan Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/03cdb77046014e689fae05c13dac66f1.jpg',
  },
  {
    id: 153,
    type: ClinicType.Type1,
    review:
      "Vetcove has been a Godsend. I was skeptical at first. I didn't really think this would save me time or money. The ease of ordering from my 3 top distributors has been pleasantly surprising. I like the ability to see all my shopping carts at once. I enjoy the other aspects of Vetcove as well, such as the order history and invoice look up features. Thank you for creating such a valuable site.",
    doctor: 'Angie Werth',
    city: 'Omaha',
    state: 'NE',
    clinic: 'Gentle Doctor Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/1b80080e4f634e55af7bf6b1fbdbce28.jpg',
  },
  {
    id: 155,
    type: ClinicType.Type1,
    review:
      'Purchasing and inventory management  without Vetcove is like using dial-up internet in today’s world. This website is absolutely indispensable. There are so many valuable features (price comparison, one-stop shopping, forums, shopping lists, purchase analytics) and it is all FREE. I urge everyone to take full advantage of this amazing website; you won’t ever go back to your old methods.',
    doctor: 'Cathy Davis, LVT',
    city: 'Greenville',
    state: 'SC',
    clinic: 'Upstate Veterinary Specialists',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ab8f000b57cb4c68819f0654839dd8f4.jpg',
  },
  {
    id: 157,
    type: ClinicType.Type1,
    review:
      'I am very thankful to have Vetcove in my life! This site saves me so much time not having to go back and forth to my different vendors. Inventory is a lot easier and faster now. I also like that there is a discussion board over many topics, it’s very helpful to reach out to other Inventory managers.',
    doctor: 'Ashley Ortiz, LVT',
    city: 'Houston',
    state: 'TX',
    clinic: 'Gulf Coast Veterinary Specialists',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5a5377e537f94d31aba05c4564e0f274.jpg',
  },
  {
    id: 147,
    type: ClinicType.Type1,
    review:
      'Vetcove is amazing and I use it everytime I am going to order. I am a new start up so paying close attention to cost and any potential savings is huge. I would highly recommend Vetcove to any veterinary practice.',
    doctor: 'Jessica Stumph, DVM',
    city: 'Kitty Hawk',
    state: 'NC',
    clinic: 'First Flight Mobile Veterinary Services',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ac80564ee8b9483c846661bdd214955d.jpg',
  },
  {
    id: 159,
    type: ClinicType.Type1,
    review:
      'Vetcove is a major process improvement to my position! The support and features that it offers are great and I would never want to “go back” to not relying on Vetcove for my daily duties! Thank you!',
    doctor: 'Jacqueline Ballay',
    city: 'Erie',
    state: 'PA',
    clinic: 'Erie Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/54f73c9958334509b474f54c2f42c5f6.jpg',
  },
  {
    id: 163,
    type: ClinicType.Type1,
    review:
      "Vetcove is a fantastic tool for any practice. The ability to quickly compare prices from different vendors is great by itself, but the developers have gone above and beyond with the purchase history analytics. We've been using Vetcove for two years now and it just keeps getting better and better.",
    doctor: 'Henry James, Practice Manager',
    city: 'Pasco',
    state: 'WA',
    clinic: 'Animal Hospital of Pasco',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/f9471d4d25044f6ca8a81a04962a6beb.jpg',
  },
  {
    id: 161,
    type: ClinicType.Type1,
    review:
      "When I first started ordering products, I was a bit overwhelmed. Sorting through each item we needed took me hours. But since we started an account with Vetcove, it's been a breeze to find which supplier to use! The site is very easy to navigate, and I'm constantly saving money through the instant price comparison. 100% would recommend!",
    doctor: 'Katie, Retail Manager',
    city: 'Loveland',
    state: 'CO',
    clinic: 'Blue Sky Animal Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/6fff7f818fa44a51842ce47050994688.jpg',
  },
  {
    id: 162,
    type: ClinicType.Type1,
    review:
      'Vetcove is a miracle! It has saved me so much time and head aches when placing my weekly orders. I cannot imagine how I ever did it before Vetcove!',
    doctor: 'Sheena Novick, CVPM',
    city: 'Sherman Oaks',
    state: 'CA',
    clinic: 'Beverly Oaks Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5a80d5ccf61f4662ba8e95a7e765ad1b.jpg',
  },
  {
    id: 168,
    type: ClinicType.Type1,
    review:
      'I love having current pricing and availability at my fingertips! Being able to price shop and arrange my orders within the shopping carts to satisfy various distributor agreements is great! Online access to various distributor catalogs has saved me so much time and headache!',
    doctor: 'Jennifer O.',
    city: 'Waterford',
    state: 'MI',
    clinic: 'Exclusively Cats Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/511915b298294969b017019447ac61b9.jpg',
  },
  {
    id: 170,
    type: ClinicType.Type1,
    review:
      'Vetcove makes it easier to price compare and shop multiple vendors at once. I love using it, and would never want to order without it.',
    doctor: 'Jessica B.',
    city: 'North Salt Lake',
    state: 'UT',
    clinic: 'Wilson Veterinary Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/a72ed7d9baf148a8a5630857593fb0e6.jpg',
  },
  {
    id: 171,
    type: ClinicType.Type1,
    review:
      'We love Vetcove!! It has completely changed the way we order and track purchasing. So much time and money saved!! Thank you Vetcove!',
    doctor: 'Rebecca I, LVT',
    city: 'Goose Creek',
    state: 'SC',
    clinic: 'Animal Medical Clinic of Goose Creek',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/21e70a53c57f481faff95c037401cba7.jpg',
  },
  {
    id: 174,
    type: ClinicType.Type1,
    review:
      "Vetcove is by far the best company I work with.  The amount of money and time I save is incredible.  I can't imagine practice life without them!",
    doctor: 'Emily Gordon, LVT',
    city: 'Charlottesville',
    state: 'VA',
    clinic: 'Autumn Trails Veterinary Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/eb7bbae63ac241019d6a4ce4a8c3c2aa.jpg',
  },
  {
    id: 175,
    type: ClinicType.Type1,
    review:
      "Vetcove has been a huge time saver for me when ordering!  I no longer have to check multiple sites for the best price or to find an item if it is back ordered from our normal company.  I also love that I can check out directly from the site instead of going to multiple web pages.  If you aren't using Vetcove you are wasting time and money!!",
    doctor: 'Ashley Willis',
    city: 'Colora',
    state: 'MD',
    clinic: 'Basin Run Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/c83c72c935a8442687e936a75e3e43f7.jpg',
  },
  {
    id: 176,
    type: ClinicType.Type1,
    review:
      'VetCove has revolutionized the way our hospital locates and orders goods. We are able to quickly check pricing against vendors, look at trends in ordering and take advantage of promotions and deals. This has helped us cut costs and decrease the amount of time spend researching and ordering.',
    doctor: 'Sam, LVT',
    city: 'Scarborough',
    state: 'ME',
    clinic: 'Maine Veterinary Medical Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/3fa1a4029c994dfb97be648cec12b673.jpg',
  },
  {
    id: 180,
    type: ClinicType.Type1,
    review:
      'Vetcove has been a game changer for me! It is incredibly simple, and saves me time and money.  Having the ability to compare prices at my finger tips has made a significant impact to our practice!!',
    doctor: 'Rachel',
    city: 'Frankfort',
    state: 'IL',
    clinic: 'Frankfort Animal Care Center',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/1fce1c6dc5694c9aa67f872af074c93f.jpg',
  },
  {
    id: 181,
    type: ClinicType.Type1,
    review:
      'VetCove has been a time and money saver for our clinic. I do all our ordering via VetCove. Being able to compare pricing and check out with all of our distributors in one location has been the most amazing thing! I would never go back to the way we use to order.',
    doctor: 'Georgia Carter',
    city: 'Wasilla',
    state: 'AK',
    clinic: 'Wasilla Veterinary Clinic',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/4145e019e67d4a808d7f31cf0ffa80bd.jpg',
  },
  {
    id: 177,
    type: ClinicType.Type1,
    review:
      'Vetcove has been an absolute time saver!  I have been able to cut my ordering time in half and the being able to compare prices feature has saved us tons of money.  I wish I had found it sooner',
    doctor: 'Brandie Jacobia',
    city: 'Pocatello',
    state: 'ID',
    clinic: 'Community Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5d9a88a7b3e4467d99d59a527184a379.jpg',
  },
  {
    id: 179,
    type: ClinicType.Type1,
    review:
      '"We had been putting off using Vetcove for some time. I decided to give it a try and we are so happy with the service. It makes price shopping so easy and the site is so user friendly. Highly recommend this to anyone who price shops. Thank You Vetcove!!\t\r\n"',
    doctor: 'Bernie',
    city: 'Lansing',
    state: 'NY',
    clinic: 'Early Winter Equine Medicine and Surgery',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/e8e97183ffb74c85a4560dd25f3ad509.jpg',
  },
  {
    id: 195,
    type: ClinicType.Type1,
    review:
      'Direct Connect (Powered by VetCove) has revolutionized our product purchasing by allowing us to rationally compare pricing and product characteristics across multiple suppliers.\r\nIt’s the best inventory control tool yet devised in veterinary medicine and a wonderful workload reducer for our staff. Keep up the great work!',
    doctor: 'Arnold L. Goldman DVM, MPH, CEM',
    city: 'Canton',
    state: 'CT',
    clinic: 'Canton Animal Hospital LLC',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/8d538c7bc35e4eeb92ad3df1b881e9e5_195.png',
  },
  {
    id: 182,
    type: ClinicType.Type1,
    review:
      '"I love Vetcove! I order inventory for 2 large veterinary practices and from multiple distributors. It is hard to remember what, when & how much I ordered. Through Vetcove, I save a lot of time and money. I can easily hover over order history and check if I recently purchased an item and which distributor shipped the item. No more ordering inventory that is already here & overlooked! Also, Vetcove quickly lists the lowest price with all your distributor accounts and moves that vendor to the top. It is so much easier to work with a budget when you have Vetcove. Even the reports are awesome! I print an order report and post in the lab at our practices so everyone will know when an item has been ordered and can verify the quantity when it arrives. LOVE LOVE LOVE!!!\t\r\n"',
    doctor: 'Mika H',
    city: 'Statesboro',
    state: 'GA',
    clinic: 'Southern Veterinary Partners',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/39fbb3fdd7a8426586382f2537915e70.jpg',
  },
  {
    id: 183,
    type: ClinicType.Type1,
    review:
      "I LOVE Vetcove!  Vetcove has really transformed my ordering process.  While doing inventory I used to have to check many different distributor websites for the best prices for options, taking hours to do so.  This being so time consuming I just started ordering from one distributor, not knowing if I could save money with other options.  Now I can easily and quickly see what other options of generics, prices and deals going on with seven different distributors at once!  Vetcove also has a awesome support staff that I can quickly chat a question and get help in minutes.  I don't know how I handled ordering before Vetcove.",
    doctor: 'Jodi See',
    city: 'Witchita',
    state: 'KS',
    clinic: 'Northrock Hospital for Animals',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/846ffcf2c4b1444ea00b2402bd66ee81.jpg',
  },
  {
    id: 184,
    type: ClinicType.Type1,
    review:
      "Vetcove is an asset to the veterinary community! It has made my job as inventory manager so much easier and it's easy to save the hospital money with comparisons at your fingertips. The message boards are a great way to get advice from colleagues!",
    doctor: 'Sean',
    city: 'Chicago',
    state: 'IL',
    clinic: 'Mid North Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ebc5c0a86e944aa4ac51f8a86ec41cb4.jpg',
  },
  {
    id: 186,
    type: ClinicType.Type1,
    review:
      "I have utilized vetcove for several years. It has been a tremendous tool for me to save time and money with sourcing medications or supplies that are on backorder to just comparing prices in one location. In addition to price comparison, vetcove is evolving and working to add additional value and support to its users. I've enjoyed utilizing the tools that vetcove has to offer and look forward to seeing and being a part of the evolution of vetcove.",
    doctor: 'Bobby Becker',
    city: 'Midlothian',
    state: 'VA',
    clinic: 'Woodlake Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/5d11287fdb5c43288e76e40ea270540e.jpg',
  },
  {
    id: 187,
    type: ClinicType.Type1,
    review:
      '"Vetcove is easy to use. I love that we customize our our orders. The support team is wonderful! \r\nMy favorite thing about Vetcove, is that it compares suppliers pricing, availability for each supplier, and the quick check out service!"',
    doctor: 'Kuuipo Betonio',
    city: 'Fayetteville',
    state: 'NC',
    clinic: 'Animal Urgent Care of Fayetteville',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/bb2842ad0c0844c8b88f916daf6dc45e.jpg',
  },
  {
    id: 188,
    type: ClinicType.Type1,
    review:
      "Vetcove allows me to compare products/pricing to make the smartest purchases for our practice.  Product reviews let me share my first-hand experience with products that might be helpful to other clinics.  I love the discussion boards!  It's so nice to be able to share ideas!",
    doctor: 'Caitlin',
    city: 'Poland',
    state: 'OH',
    clinic: 'Poland Veterinary Centre',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/988da69c81d6442db2c1ac800e3dd5aa_188.jpg',
  },
  {
    id: 189,
    type: ClinicType.Type1,
    review:
      'I was new to inventory and Vetcove really helped me adjust from being a tech to an inventory manager. To have one place I can compare prices, order and find out answers to questions I have is amazing. It really is an amazing tool.',
    doctor: 'Samantha',
    city: 'Seven Hills',
    state: 'OH',
    clinic: 'Broadview Animal and Bird Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/930f4cd6dddd4142b30eb813e598b41b_189.jpg',
  },
  {
    id: 190,
    type: ClinicType.Type1,
    review:
      '"Vetcove saves me so much time each week. I no longer have to float between the different distributer sites looking for product/prices. The Vetcove staff has been very helpful with any questions and the discussion boards are a great source of information as well. \r\n"',
    doctor: 'Jess',
    city: 'Marshall',
    state: 'MN',
    clinic: 'Evans Family Pet Care',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/62c974dd973e449f980edefe6bdcd639.jpg',
  },
  {
    id: 191,
    type: ClinicType.Type1,
    review:
      'Vetcove has been a wonderful tool for ordering our weekly inventory.  It is fantastic to have all the products and pricing in one location.  This has DEFINITELY streamlined our system.',
    doctor: 'Danette Manning, LVMT',
    city: 'Mt. Juliet',
    state: 'TN',
    clinic: 'Blue Oasis Pet Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/ed078f8e48074cf88542956c16d8f236.jpg',
  },
  {
    id: 192,
    type: ClinicType.Type1,
    review:
      'Vetcove has benefited our practice as a one stop shop. So,my office can do other things in our practice other than talk to distributors and check prices.',
    doctor: 'Laura DuVall Molony',
    city: 'Canton',
    state: 'GA',
    clinic: 'Georgia Equine Veterinary Services',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/0d5689eb6c344843a5db30c969f7fcaa.jpg',
  },
  {
    id: 193,
    type: ClinicType.Type1,
    review:
      'Vetcove is awesome! It truly offers the best way to have be able to see which of your distributors have availabilty of an item at a quick glance. With all the chronic backorders of so many products this is truly the quickest way to find what you need and get it on its way without making 4 phone calls or checking 4 websites.',
    doctor: 'Lorrie Powalski, LVT',
    city: 'Fairbanks',
    state: 'AK',
    clinic: 'Mt. McKinley Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/cabbb38843af455b84ddc5ee9f15dfe3.jpg',
  },
  {
    id: 194,
    type: ClinicType.Type1,
    review:
      'Vetcove is so easy and convenient, all the products in one place with all the pricing viewable. You can see prodcuts that are out of stock and in different warehouse etc. Basically vetcove is amazing.',
    doctor: 'Keri',
    city: 'Fredericksburg',
    state: 'VA',
    clinic: 'St Francis Animal Hospital',
    image:
      'https://media.vetcove.com/media/general/testimonial/wallpaper/29dc73fbdfbe4a46bbcd58279ea63083.jpg',
  },
];
