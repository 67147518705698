/**
 * Video urls used throughout the app
 */
const videos = {
  coveCrew: 'https://media.vetcove.com/static/video/ambassador.mp4',
  cashback: 'https://player.vimeo.com/video/315136548',
  testimonial: 'https://player.vimeo.com/video/644946121',
};

export default videos;
